
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { deleteClaim, getClaim, updateClaim } from "@/api/claim.api";
import { useRoute, useRouter } from "vue-router";
import {
  getClearinghouseStatuses,
  getDictionary,
  getTypeOfBillCode,
} from "@/api/dictionaries.api";
import Multiselect from "@vueform/multiselect";
import { Claim, ClaimStatusUser } from "@/modules/claims/claim.model";
import { useStore } from "vuex";
import { getPanel } from "@/api/encounter.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import HourComponent from "@/components/ABilling/TimeComponent.vue";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Data {
  isDirty: boolean;
  claimPrevious?: Claim;
  panel: any;
  claim?: Claim;
  patient: unknown;
  dict: {
    isDurty: boolean;
    typeOfFacility: [];
    typeOfCare: [];
    frequency: [];
    admissionType: [];
    admissionSource: [];
    dischargeStatus: [];
    claimStatuses: [];
    boolList: { code?: boolean | null; name: string }[];
    billingProviders: [];
    attendingProviders: [];
    clearinghouseStatuses: { code: string; description: string }[];
  };
}

export default defineComponent({
  name: "FacilityClaimComponent",
  props: ["claim", "claimId"],
  components: { Multiselect, DecimalInput, HourComponent, DateFloatComponent },
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      const todest = to as any;
      //spec title for Back Button
      if (todest && todest.path == "/claimManagement") {
        text = "Are you sure you want to leave without saving changes?";
      }
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();

    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      panel: { facilities: [], patients: [], providers: [] },
      patient: undefined,
      claim: undefined,
      dict: {
        isDurty: false,
        typeOfFacility: [],
        typeOfCare: [],
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
        claimStatuses: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
        ],
        billingProviders: [],
        attendingProviders: [],
        clearinghouseStatuses: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.panel = await getPanel();
      data.dict.billingProviders = data.panel.facilities.filter((c) => {
        return c.billingProvider;
      });
      data.dict.attendingProviders = data.panel.providers.filter((c) => {
        return c.attending;
      });
      await getDict();

      data.dict.claimStatuses = store.getters.allClaimStatuses;
      const claim = await getClaim(props.claim.id);
      data.patient = claim.encounter.patient;
      data.claim = claim;
      data.claimPrevious = JSON.parse(JSON.stringify(data.claim));
    });

    watch(
      () => data.claim,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.claimPrevious &&
          JSON.stringify(currentValue) != JSON.stringify(data.claimPrevious)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    async function getDict() {
      const typeOfBillCodes = await getTypeOfBillCode();

      data.dict.typeOfFacility = typeOfBillCodes.filter((item) => {
        return item.level == 1;
      });
      data.dict.typeOfCare = typeOfBillCodes.filter((item) => {
        return item.level == 2;
      });
      data.dict.frequency = typeOfBillCodes.filter((item) => {
        return item.level == 3;
      });
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
      data.dict.clearinghouseStatuses = await getClearinghouseStatuses();
    }

    async function deleteClaimData() {
      await deleteClaim(data.claim);
      ctx.emit("claimDeleted");
    }

    async function cancel() {
      const res = await Swal.fire({
        title: "Are you sure you want to leave without saving changes?",
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      });
      if (res.isConfirmed) {
        data.claim = await getClaim(props.claimId);
        data.isDirty = false;
      }
    }

    function billingProviderChanged() {
      if (data.claim) {
        const facility = data.panel.facilities.find((c) => {
          return c.id == data.claim?.facilityClaim.facilityBillingProviderId;
        });
        data.claim.facilityClaim.taxId = facility.taxId;
      }
    }

    const getPayer = computed(() => {
      if (data.claim?.payer) {
        return `${data.claim?.payer.name} (${data.claim?.payer.subscriberIdNumber})`;
      }
      return "none";
    });

    function getWaystarStatus(code) {
      const status = data.dict.clearinghouseStatuses.find((item) => {
        return item.code == code;
      });
      if (status) {
        return status.description;
      }
      return code;
    }

    function goToPayer() {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        path:
          "/patientAccounts/patient/" +
          data.claim?.encounter.patientId +
          "/patientInsurance/" +
          data.claim?.payerId,
        query: { breadcrumb: path },
      });
    }

    function goTo(initpath, value) {
      if (value) {
        const path = encodeURIComponent(route.fullPath);
        router.push({
          path: initpath + value,
          query: { breadcrumb: path },
        });
      }
    }

    function goToFacility(id) {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        name: "facilityPage",
        params: {
          facilityId: id,
        },
        query: { breadcrumb: path },
      });
    }

    async function update() {
      const res = await updateClaim(data.claim);
      if (res) {
        await nextTick();
        data.claim = await getClaim(data.claim?.id);
        Swal.fire({
          title: "Claim details saved successfully",
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
        ctx.emit("claimUpdated", data.claim);
      }
    }

    async function valueChanged() {
      data.isDirty = true;
      await nextTick();
    }

    const isVoided = computed(() => {
      return (
        data.claim?.status == ClaimStatusUser.Voided ||
        data.claim?.status == ClaimStatusUser.Closed
      );
    });

    const isReadOnly = computed(() => {
      //return data.encounter?.status == "SubmittedToClearinghouse";
      return true;
    });

    return {
      organizationId,
      goTo,
      goToFacility,
      goToPayer,
      billingProviderChanged,
      data,
      deleteClaimData,
      getWaystarStatus,
      valueChanged,
      isReadOnly,
      isVoided,
      getPayer,
      update,
      cancel,
    };
  },
});
