
import {
  defineComponent,
  onMounted,
  ref,
  reactive,
  computed,
  watch,
  nextTick,
} from "vue";
import { getOrganization } from "@/core/services/JwtService";
import DecimalInput from "@/components/ABilling/DecimalInput.vue";
import { getClaim, updateClaim } from "@/api/claim.api";
import { useRoute, useRouter } from "vue-router";
import {
  getClearinghouseStatuses,
  getDictionary,
  getTypeOfBillCode,
} from "@/api/dictionaries.api";
import Multiselect from "@vueform/multiselect";
import { Claim, ClaimStatusUser } from "@/modules/claims/claim.model";
import { useStore } from "vuex";
import { getPanel } from "@/api/encounter.api";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { getReferringProviders } from "@/api/referringProvider.api";
import DateFloatComponent from "@/components/ABilling/DateFloatComponent.vue";

export interface Data {
  isDirty: boolean;
  claimPrevious?: Claim;
  panel: any;
  claim?: Claim;
  patient: unknown;
  dict: {
    isDirty: boolean;
    frequency: [];
    admissionType: [];
    admissionSource: [];
    dischargeStatus: [];
    claimStatuses: [];
    boolList: { code?: boolean | null; name: string }[];
    billingProviders: [];
    attendingProviders: [];
    clearinghouseStatuses: { code: string; description: string }[];
    referringProviders: [];
  };
}

export default defineComponent({
  name: "ProfessionalClaimComponent",
  props: ["claim", "claimId"],
  components: { Multiselect, DecimalInput, DateFloatComponent },
  beforeRouteLeave(to, from, next) {
    if (this.data.isDirty) {
      let text = "Are you sure you want to leave without saving changes?";
      const todest = to as any;
      //spec title for Back Button
      if (todest && todest.path == "/claimManagement") {
        text = "Are you sure you want to leave without saving changes?";
      }
      Swal.fire({
        title: text,
        showDenyButton: true,
        showCancelButton: true,
        confirmButtonText: "Yes",
        denyButtonText: "No",
        allowOutsideClick: false,
        customClass: {
          cancelButton: "ab-button-secondary",
          confirmButton: "ab-button",
        },
      }).then((result) => {
        if (result.isConfirmed) {
          next();
        } else if (result.isDismissed) {
          next(false);
        }
      });
    } else {
      next();
    }
  },
  setup(props, ctx) {
    const store = useStore();
    const route = useRoute();

    const router = useRouter();
    let organizationId = ref<string | null>("");
    let data = reactive<Data>({
      isDirty: false,
      panel: { facilities: [], patients: [], providers: [] },
      patient: undefined,
      claim: undefined,
      dict: {
        isDirty: false,
        frequency: [],
        admissionType: [],
        admissionSource: [],
        dischargeStatus: [],
        claimStatuses: [],
        boolList: [
          { code: true, name: "Yes" },
          { code: false, name: "No" },
        ],
        billingProviders: [],
        attendingProviders: [],
        clearinghouseStatuses: [],
        referringProviders: [],
      },
    });

    onMounted(async () => {
      organizationId.value = getOrganization();
      data.panel = await getPanel();
      data.dict.billingProviders = data.panel.facilities.filter((c) => {
        return c.billingProvider;
      });
      data.dict.attendingProviders = data.panel.providers.filter((c) => {
        return c.attending;
      });
      await getDict();

      data.dict.claimStatuses = store.getters.allClaimStatuses;
      data.dict.referringProviders = await getReferringProviders();
      const claim = await getClaim(props.claim.id);
      data.patient = claim.encounter.patient;
      data.claim = claim;
      data.claimPrevious = JSON.parse(JSON.stringify(data.claim));
    });

    watch(
      () => data.claim,
      (currentValue, oldValue) => {
        if (
          currentValue &&
          data.claimPrevious &&
          JSON.stringify(currentValue) != JSON.stringify(data.claimPrevious)
        ) {
          data.isDirty = true;
        }
      },
      { deep: true }
    );

    async function getDict() {
      data.dict.admissionType = await getDictionary(7, "");
      data.dict.admissionSource = await getDictionary(8, "");
      data.dict.dischargeStatus = await getDictionary(9, "");
      data.dict.clearinghouseStatuses = await getClearinghouseStatuses();
      const typeOfBillCodes = await getTypeOfBillCode();
      data.dict.frequency = typeOfBillCodes.filter((item) => {
        return item.level == 3;
      });
    }

    const getPayer = computed(() => {
      if (data.claim?.payer) {
        return `${data.claim?.payer.name} (${data.claim?.payer.subscriberIdNumber})`;
      }
      return "none";
    });

    function getWaystarStatus(code) {
      const status = data.dict.clearinghouseStatuses.find((item) => {
        return item.code == code;
      });
      if (status) {
        return status.description;
      }
      return code;
    }

    function goToPayer() {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        path:
          "/patientAccounts/patient/" +
          data.claim?.encounter.patientId +
          "/patientInsurance/" +
          data.claim?.payerId,
        query: { breadcrumb: path },
      });
    }

    function goTo(initpath, value) {
      if (value) {
        const path = encodeURIComponent(route.fullPath);
        router.push({
          path: initpath + value,
          query: { breadcrumb: path },
        });
      }
    }

    function goToFacility(id) {
      const path = encodeURIComponent(route.fullPath);
      router.push({
        name: "facilityPage",
        params: {
          facilityId: id,
        },
        query: { breadcrumb: path },
      });
    }

    function goToReferringProvider(id) {
      if (!id) {
        return;
      }

      const path = encodeURIComponent(route.fullPath);
      router.push({
        name: "referringProviderPage",
        params: {
          referringProviderId: id,
        },
        query: { breadcrumb: path },
      });
    }

    const isReadOnly = computed(() => {
      //return data.encounter?.status == "SubmittedToClearinghouse";
      return true;
    });

    async function update() {
      const res = await updateClaim(data.claim);
      if (res) {
        await nextTick();
        data.claim = await getClaim(data.claim?.id);
        ctx.emit("claimUpdated", data.claim);
        Swal.fire({
          title: "Claim details saved successfully",
          confirmButtonText: "Ok!",
          icon: "success",
          customClass: {
            confirmButton: "ab-button",
          },
        });
      }
    }

    const isVoided = computed(() => {
      return data.claim?.status == ClaimStatusUser.Voided || data.claim?.status == ClaimStatusUser.Closed;
    });

    return {
      organizationId,
      goTo,
      goToFacility,
      goToPayer,
      goToReferringProvider,
      data,
      getWaystarStatus,
      update,
      isReadOnly,
      isVoided,
      getPayer,
    };
  },
});
